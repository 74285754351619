<template>
<div id="projects">
	<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
    <section id="inner-headline">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="pageTitle">Projects</h2>
                </div>
            </div>
        </div>
    </section>
    <section id="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <table class="table table-striped table-condensed">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Project Name</th>
                                <th>Client</th>
                                <th>Scope</th>
                                <th>Location</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in rows" :key="row.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ row.title }}</td>
                                <td>{{ row.client }}</td>
                                <td>{{ row.scope }}</td>
                                <td>
                                   {{ row.location }}
                                </td>
                                <td>
                                    <a data-toggle="modal" :data-target="'#modal-project-'+row.id" href="javascript: void(0);">
										<i class="fa fa-search"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="!rows.length">
                                <td colspan="4" align="center">
                                    No Records Found.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <div v-for="row in rows" :key="row.id" :id="'modal-project-'+row.id" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        {{ row.title }}
                    </h4>
                </div>
                <div class="modal-body">
                    <div>
                        <img style="max-width: 100%; border: 3px solid #171717; box-shadow: 0px 0px 10px #BE8844;" :src="$uploadsURL+'projects/'+row.image">
                    </div>
                    <h5 v-html="row.location"></h5>
                    <p v-html="row.description"></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
						Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script type="text/javascript" scoped>
export default{
	name: "ProjectsView",
	data(){
		return {
			rows: [],
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_projects",
		}).then((res) => {
			this.rows = res.data;
			this.$refs.topProgress.done();
		});
	}
}
</script>

<style type="text/css" scoped>
</style>